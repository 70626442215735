import * as KatalMetrics from "@katal/metrics";
import KatalMetricsDriverSushi from "@katal/metricsDriverSushi";

// Further information: https://katal.corp.amazon.com/#/metrics/intro

// Default all non-pipeline related environments(i.e. dev desktop) to use test domain and test metric.
let domain = "test";
let serviceName = "MidwayAuthPortalTest";

// Published metrics will have this realm unless reaching China partition.
let realm = "USAmazon";

const currentHostName = window.location.hostname;

// If we are using a production or gamma/beta URL, emit partition specific metric in correct domain.
switch (currentHostName) {
  case "midway-auth.amazon.com":
    domain = "prod";
    serviceName = "MidwayAuthPortalCommercial";
    break;
  case "midway-auth.aws-border.cn":
    domain = "prod";
    realm = "CNAmazon";
    serviceName = "MidwayAuthPortalChina";
    break;
  case "midway-auth-itar.amazon.com":
    domain = "prod";
    serviceName = "MidwayAuthPortalGovCloud";
    break;
  case "midway-auth.lck.aws-border.com":
    domain = "prod";
    serviceName = "MidwayAuthPortalLCK";
    break;
  case "midway-auth.sc2s.sgov.gov":
    domain = "prod";
    serviceName = "MidwayAuthPortalLCK";
    break;
  case "midway-auth.c2s.ic.gov":
    domain = "prod";
    serviceName = "MidwayAuthPortalDCA";
    break;
  case "auth.midway.csphome.adc-e.uk":
    domain = "prod";
    serviceName = "MidwayAuthPortalNCL";
    break;
  case "auth.midway.csphome.hci.ic.gov":
    domain = "prod";
    serviceName = "MidwayAuthPortalALE";
    break;
  case "midway-auth-integ.aka.amazon.com":
    domain = "gamma";
    serviceName = "MidwayAuthPortalDevo";
    break;
  case "midway-auth-integ.us-east-1.amazon.com":
    domain = "gamma";
    serviceName = "MidwayAuthPortalDevo";
    break;
  case "midway-auth-gamma.bjs.aws-border.cn":
    realm = "CNAmazon";
    serviceName = "MidwayAuthPortalChinaDevo";
    break;
  case "midway-auth-gamma.pdt.aws-border.com":
    serviceName = "MidwayAuthPortalGovCloudDevo";
    break;
  case "midway-auth-gamma.lck.amazon.com":
    serviceName = "MidwayAuthPortalLCKDevo";
    break;
  case "midway-auth-gamma.sc2s.sgov.gov":
    serviceName = "MidwayAuthPortalLCKDevo";
    break;
  case "midway-auth-integ.c2s-border.ic.gov":
    serviceName = "MidwayAuthPortalDCADevo";
    break;
  case "auth-integ.midway.csphome.adc-e.uk":
    serviceName = "MidwayAuthPortalNCLDevo";
    break;
  case "auth-integ.midway.csphome.hci.ic.gov":
    serviceName = "MidwayAuthPortalALEDevo";
    break;
}

const metricsErrorHandler = (err: any) => {
  console.error(err);
};

const metricsDriver = new KatalMetricsDriverSushi.Builder()
  .withDomainRealm(domain, realm)
  .withErrorHandler(metricsErrorHandler)
  .build();

const initialMetricsContext = new KatalMetrics.Context.Builder()
  .withSite("MidwayAuthPortal")
  .withServiceName(serviceName)
  .build();

const initialMetricsPublisher = new KatalMetrics.Publisher(metricsDriver, metricsErrorHandler, initialMetricsContext);

declare global {
  interface Window {
    MidwayAuthClientSideMetrics?: {
      initialMetricsPublisher: KatalMetrics.Publisher;
    };
  }
}

window.MidwayAuthClientSideMetrics = {
  initialMetricsPublisher,
};
